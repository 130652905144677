import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Button } from '../common';
import './ReportingTable.scss';
import _isEmpty from 'lodash-es/isEmpty';
import {
  createRoomHistory,
  deleteRoomReport,
  swapRoomReports,
} from '../../redux/modules/Data/operations';
import AddResourceModal from '../AddResourceModal/AddResourceModal';
import FeatureTypes from '../../utils/FeatureTypes';
import { useParams } from 'react-router';
import handlePath from '../../utils/handlePath';
// import SortDropdown from '../SortDropdown';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ReportingRow } from './ReportingRow';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer';
import DataLedgerModal from '../DataLedgerModal';
// import {
//   createSharedReport,
//   updateSharedReport,
// } from '../../redux/modules/Company/operations';
import UserReportsModal from '../UserReportsModal/UserReportsModal';
// import { setNotice } from '../../redux/modules/UI/actions';
dayjs.extend(customParseFormat);

export const massageResource = (features, featureTypes, resourceId) => {
  const isResourceEmpty = features[0]?.isResourceEmpty || false;
  const isDeletable = !features[0]?.finalized || false;
  let companyName = '';
  let resourceItems = [];
  let documentItems = [];
  let documentsCompleted = 0;
  let requiredDocumentsCompleted = 0;
  let resourceName = '';

  featureTypes.forEach(e => {
    const featureIndex = features.findIndex(v => v.feature_type_id === e.feature_type_id);
    let feature = features[featureIndex] || {};
    let accountName = feature?.account?.name;
    if (!!accountName) {
      companyName = accountName;
    }
    if (feature.resource) {
      resourceName = feature.resource;
    }
    e.initialValue = feature.value || e.default_value || '';
    e.defaultValue = e.default_value || '';
    e.resourceId = feature.resource_id;

    if (
      e.feature_type.feature_type === 'boolean' &&
      (feature.value === '0' || feature.value === 'false')
    ) {
      e.initialValue = false;
    } else if (e.feature_type.feature_type === 'address') {
      let matches =
        (feature.value || '').match(/(.*)\n(.*)\n(.*), ([a-z\s]*)(.*)/i) || [];
      e.defaultValue = {
        line_one: '',
        line_two: '',
        city: '',
        state: '',
        zip: '',
      };
      if (!feature.value) {
        e.initialValue = e.defaultValue;
      } else if (matches.length === 0) {
        e.defaultValue.line_one = feature.value;
        e.initialValue = e.defaultValue;
      } else {
        e.initialValue = {
          line_one: matches[1] || '',
          line_two: matches[2] || '',
          city: matches[3] || '',
          state: matches[4] || '',
          zip: matches[5] || '',
        };
      }
    } else if (e.feature_type.feature_type === 'address_universal') {
      let matches =
        (feature.value || '').match(/(.*)\n(.*)\n(.*), ([a-z\s]*) (.*)?(\n(.*))?/i) || [];
      e.defaultValue = {
        line_one: '',
        line_two: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States of America',
      };
      if (!feature.value) {
        e.initialValue = e.defaultValue;
      } else if (matches.length === 0) {
        e.defaultValue.line_one = feature.value;
        e.initialValue = e.defaultValue;
      } else {
        e.initialValue = {
          line_one: matches[1] || '',
          line_two: matches[2] || '',
          city: matches[3] || '',
          state: matches[4] || '',
          zip: matches[5] || '',
          country: matches[7] || 'United States of America',
        };
      }
    } else if (e.feature_type.feature_type === 'date' && feature.value) {
      e.initialValue = dayjs(feature.value, [
        'MM-DD-YYYY',
        'MM/DD/YYYY',
        'MMM Do, YYYY',
        'D MMM YYYY',
        'MMM D, YYYY',
      ]).format('MM/DD/YYYY');
    }
    let label = feature.value || '--';

    if (e.feature_type.feature_type === 'percent' && feature.value) {
      label = feature.value + '%';
    } else if (e.feature_type.feature_type === 'document') {
      e.isDocument = true;
      e.initialValue = feature.value;

      if (feature.value) {
        documentsCompleted++;
        label = 'Preview File';
        if (e.new_require) {
          requiredDocumentsCompleted++;
        }
      }
      documentItems.push({ ...e, feature });
    } else if (
      e.feature_type.feature_type &&
      e.feature_type.feature_type.includes('address') &&
      feature.value
    ) {
      let newLabel = [];
      let splitValue = label.split('\n');
      splitValue.forEach(e => {
        if (e.trim()) {
          newLabel.push(e);
        }
      });
      label = newLabel.join('\n') || '--';
    }
    if (label.indexOf('|') !== -1) {
      label = label.split('|').join(', ');
    }
    if (!feature.value && !_isEmpty(e.action)) {
      label = e.action.label;
    }
    e.label = label;
    resourceItems.push({ ...e, feature });
  });
  if (companyName) {
    resourceName += ` (${companyName})`;
  }
  return {
    companyName,
    isDeletable,
    isResourceEmpty,
    resourceItems,
    documentsCompleted,
    documentItems,
    requiredDocumentsCompleted,
    resourceId,
    resourceName,
  };
};

export const massageResourceList = (
  resourceList,
  featureTypes,
  sortSlug,
  isSortReversed,
) => {
  let list = Object.entries(resourceList).sort(sortTable(sortSlug));
  if (isSortReversed || sortSlug === 'none') {
    list.reverse();
  }
  if (list.length === 0) {
    list.push(['', [{ isResourceEmpty: true }]]);
  }
  list = list.map(([key, features]) => massageResource(features, featureTypes, key));
  return list;
};

const sortTable = slug => (a, b) => {
  let aIndex = a[1].findIndex(e => e.feature === slug);
  let bIndex = b[1].findIndex(e => e.feature === slug);
  if (slug === 'none') {
    aIndex = a[1].findIndex(e => e.feature === 'name' || e.feature === 'operating_state');
    bIndex = b[1].findIndex(e => e.feature === 'name' || e.feature === 'operating_state');
  }
  if (aIndex === -1) {
    aIndex = 0;
  }
  if (bIndex === -1) {
    bIndex = 1;
  }
  const aItem = a[1][aIndex] || {};
  const bItem = b[1][bIndex] || {};
  if (slug === 'none') {
    const aDate = dayjs(aItem.submission_date || aItem.created_at);
    const bDate = dayjs(bItem.submission_date || bItem.created_at);
    return FeatureTypes['date'].compareFunc({ value: aDate }, { value: bDate });
  } else if (!aItem && bItem) {
    return 1;
  } else if (aItem && !bItem) {
    return -1;
  } else if (!aItem && !bItem) {
    return 0;
  }
  return FeatureTypes[aItem.feature_type].compareFunc(aItem, bItem);
};

const ReportingTable = ({
  accessCode,
  accountId,
  id,
  isAdmin,
  isEditing,
  isInitCollapsed,
  isUser,
  json = {},
  passedParams = {},
  question_label,
  report = {},
  resourceListData = {},
  roomId,
  room_report_id,
  reportIndex,
  reports,
  selectedPortfolio,
  user_only,
}) => {
  const {
    // allow_new,
    feature_types = [],
    // links = [],
    // resource_label,
    resource_type_id,
  } = json;
  // const { id: reportId, is_active, metadata = {} } = report || {};
  const dispatch = useDispatch();
  const routeParams = useParams();
  const params = _isEmpty(passedParams) ? routeParams : passedParams;
  const { companyId } = params;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});
  const [isSortReversed] = useState(false);

  const [isCollapsed] = useState(isInitCollapsed);
  const [isLedgerShowing, setIsLedgerShowing] = useState(false);
  const [isUserReportsShowing, setIsUserReportsShowing] = useState(false);

  let tableSortOptions = useMemo(() => {
    let sortOptions = {};
    let documentsTotal = 0;
    let requiredDocumentsTotal = 0;
    feature_types
      .filter(e => e.table_view)
      .forEach(e => {
        if (e.feature_type.feature_type === 'document') {
          documentsTotal++;
          if (e.new_require) {
            requiredDocumentsTotal++;
          }
        }
        sortOptions[e.feature_type.name] = {
          label: e.feature_name,
          slug: e.feature_type.name,
          iconType: 'a-z',
        };
      });
    return {
      documentsTotal,
      requiredDocumentsTotal,
      sortOptions: {
        ...sortOptions,
        none: {
          slug: 'none',
          label: 'Default',
        },
      },
    };
  }, [feature_types]);

  const [sortOption] = useState(tableSortOptions.sortOptions.none);
  const { slug } = sortOption;

  const { isDocumentsInTable, resourcesList } = useMemo(() => {
    const resourcesList = massageResourceList(
      resourceListData,
      feature_types,
      slug,
      isSortReversed,
    );
    let tableDocumentsTotal = 0;
    let isDocumentsInTable = false;
    resourcesList.forEach(e => {
      tableDocumentsTotal += e.documentsCompleted;
      if (e.resourceItems.findIndex(item => item.isDocument) !== -1) {
        isDocumentsInTable = true;
      }
    });
    return {
      isDocumentsInTable,
      resourcesList,
      tableDocumentsTotal,
    };
  }, [resourceListData, feature_types, slug, isSortReversed]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedResource({});
  };

  // const handleSortRooms = selectedOption => {
  //   let updatedIsSortReversed = false;
  //   if (selectedOption.slug === slug) {
  //     updatedIsSortReversed = !isSortReversed;
  //   }
  //   setIsSortReversed(updatedIsSortReversed);
  //   setSortOption(selectedOption);
  // };
  // const handleAddResourceClick = () => {
  //   setSelectedResource(massageResourceList([], feature_types)[0]);
  //   setIsNew(true);
  //   setIsModalOpen(true);
  // };
  const handleSortUpClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomReports(
        room_report_id,
        reports[reportIndex - 1].room_report_id,
        roomId,
        accountId,
        accessCode,
      ),
    );
  };
  const handleSortDownClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomReports(
        room_report_id,
        reports[reportIndex + 1].room_report_id,
        roomId,
        accountId,
        accessCode,
      ),
    );
  };

  const isTableEmpty = resourcesList.length < 1 || resourcesList[0].isResourceEmpty;

  return (
    <div className="reportingTable__wrapper">
      <div
        // onClick={e => {
        //   if (e.defaultPrevented || isTableEmpty) {
        //     return;
        //   }
        //   // setCollapsed(!isCollapsed);
        // }}
        className={classNames('reportingTable__title', {
          'reportingTable__title--empty': isTableEmpty,
          'reportingTable__title--collapsed': isCollapsed,
        })}
      >
        <div className="reportingTable__titleCol reportingTable__titleCol--first">
          <h2 className="reportingTable__toggleCollapse">
            {/* {!isTableEmpty && (
              <FontAwesomeIcon
                className="reportingTable__toggleIcon"
                icon={['fal', isCollapsed ? 'chevron-right' : 'chevron-down']}
              />
            )} */}
            {question_label}
            <Button
              buttonType="icon"
              size="sm"
              className="dataTable__ledgerAction"
              onClick={ev => {
                ev.preventDefault();
                setIsLedgerShowing(true);
              }}
              tooltip="Download Report as CSV"
            >
              <FontAwesomeIcon icon={['fal', 'file-download']} />
            </Button>
            <div className="reportingTable__divider" />
            {/* {!accessCode && !roomId && resourcesList.length > 0 && !isUser && (
              <DropdownAction className="reportingTable__dropdownWrapper" left>
                <div className="reportingTable__dropdownHeader">
                  <h4>Reports Table Options</h4>
                </div>
                <div className="reportingTable__dropdownDivider" />
                <Button
                  buttonType="secondary"
                  size="sm"
                  onClick={ev => {
                    ev.preventDefault();
                    setIsLedgerShowing(true);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'file-download']} />
                  Download Report as CSV
                </Button>
                {!isUser && (
                  <Button
                    buttonType="secondary"
                    size="sm"
                    isDisabled={!isEmailAvailable}
                    onClick={ev => {
                      ev.preventDefault();
                      setIsUserReportsShowing(true);
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'file-circle-plus']} />
                    Manage Report Sharing
                  </Button>
                )}
                {!isUser && (
                  <Button
                    buttonType="secondary"
                    size="sm"
                    isDisabled={!isEmailAvailable}
                    onClick={ev => {
                      ev.preventDefault();
                      if (!!reportId) {
                        dispatch(
                          updateSharedReport(accountId, reportId, {
                            is_active: is_active ? 0 : 1,
                            metadata,
                          }),
                        ).then(p => dispatch(setNotice('Report Sharing Updated.')));
                      } else {
                        dispatch(
                          createSharedReport(accountId, {
                            feature_type_id: id,
                          }),
                        ).then(p =>
                          dispatch(setNotice('Report now showing in User Dashboards.')),
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={is_active ? 'eye-slash' : 'eye'} />
                    {is_active
                      ? 'Hide Report in User Dashboard'
                      : 'Show Report in User Dashboards'}
                  </Button>
                )}
              </DropdownAction>
            )} */}
            {/* {!accessCode && !roomId && resourcesList.length > 0 && isUser && (
              <Button
                buttonType="icon"
                size="sm"
                className="reportingTable__ledgerAction"
                onClick={ev => {
                  ev.preventDefault();
                  setIsLedgerShowing(true);
                }}
                tooltip="Download Report as CSV"
              >
                <FontAwesomeIcon icon={['fal', 'file-download']} />
              </Button>
            )} */}
            {isEditing && (
              <>
                <Button
                  buttonType="icon"
                  size="sm"
                  isDisabled={reportIndex === 0}
                  onClick={handleSortUpClick}
                  tooltip="Move up"
                >
                  <FontAwesomeIcon icon="arrow-up" />
                </Button>
                <Button
                  buttonType="icon"
                  size="sm"
                  isDisabled={reportIndex + 1 === reports.length}
                  onClick={handleSortDownClick}
                  tooltip="Move down"
                >
                  <FontAwesomeIcon icon="arrow-down" />
                </Button>
                <Button
                  buttonType="icon"
                  size="sm"
                  isWarning
                  onClick={ev => {
                    ev.preventDefault();
                    dispatch(
                      deleteRoomReport(room_report_id, roomId, accountId, accessCode),
                    ).then(p =>
                      dispatch(
                        createRoomHistory(
                          accessCode,
                          {
                            action: 'remove_report',
                            type: 'remove',
                            label: `Removed ${question_label} Report from Room.`,
                          },
                          roomId,
                        ),
                      ),
                    );
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                </Button>
              </>
            )}
          </h2>
        </div>
        {/* {isAddDataShowing && (
          <Button
            buttonType="link"
            className="reportingTable__add"
            onClick={handleAddResourceClick}
          >
            {`Add${
              ['Director', 'Employee'].includes(resource_label) ? '/Remove' : ''
            } ${resource_label}`}
          </Button>
        )} */}
        {/* {isSortShowing && (
          <SortDropdown
            isSortReversed={isSortReversed}
            onSelect={handleSortRooms}
            sortOption={sortOption}
            sortOptions={tableSortOptions.sortOptions}
          />
        )} */}
        {/* <div className="reportingTable__titleSummary">
          <h4>Entries</h4>
          <h2>
            <FontAwesomeIcon icon={['fal', 'users']} />
            {isTableEmpty ? 0 : resourcesList.length}
          </h2>
        </div> */}
        {/* <div className="reportingTable__titleSummary reportingTable__titleSummary--last">
          <h4>Records</h4>
          <h2>
            <FontAwesomeIcon icon={['fal', 'file-alt']} />
            {tableDocumentsTotal}
          </h2>
        </div> */}
      </div>
      {!!isTableEmpty && isAdmin && (
        <div className="reportingTable">
          <h2 className="reportingTable__userOnly">*Users will not see empty tables.*</h2>
        </div>
      )}
      {!isTableEmpty && isAdmin && !!user_only && (
        <div className="reportingTable">
          <h2 className="reportingTable__userOnly">
            *Users will see only their specific Reporting Info here.*
          </h2>
        </div>
      )}
      {!isTableEmpty && !isCollapsed && (
        <div className="reportingTable">
          {resourcesList.length > 0 && (
            <div className="reportingTable__resourceHeaders">
              {resourcesList[0].resourceItems.map(
                (item, ind) =>
                  !item.isDocument &&
                  !!item.table_view && (
                    <div
                      className={classNames('reportingTable__resourceHeader', {
                        'reportingTable__resourceHeader--first': ind === 0,
                      })}
                      key={`resource-header-${item.feature_name}-${ind}`}
                    >
                      <h4>
                        {item.feature_name}
                        {ind === 0 && <FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                      </h4>
                    </div>
                  ),
              )}
              {isDocumentsInTable && (
                <div className="reportingTable__resourceHeader reportingTable__resourceHeader--documents">
                  <h4>Documents</h4>
                </div>
              )}
            </div>
          )}
          {resourcesList.map((resource, resourceIndex) => (
            <ReportingRow
              key={`resource-${resourceIndex}`}
              accessCode={accessCode}
              companyId={companyId}
              documentsTotal={tableSortOptions.documentsTotal}
              isTableFormat={true}
              isAdmin={isAdmin}
              isUser={isUser}
              requiredDocumentsTotal={tableSortOptions.requiredDocumentsTotal}
              resource={resource}
              reports_feature_type_id={id}
              resourceIndex={resourceIndex}
              roomId={roomId}
              setIsModalOpen={setIsModalOpen}
              setSelectedResource={setSelectedResource}
            />
          ))}
        </div>
      )}
      <AddResourceModal
        accountId={accountId}
        accessCode={accessCode}
        documentsTotal={tableSortOptions.documentsTotal}
        requiredDocumentsTotal={tableSortOptions.requiredDocumentsTotal}
        reports_feature_type_id={id}
        handleClose={handleCloseModal}
        resourceTypeId={resource_type_id}
        isLocked={
          (!!accessCode && !isAdmin) || (!!accessCode && isAdmin && !isEditing) || isUser
        }
        isOpen={isModalOpen}
        isNew={isNew}
        json={json}
        setIsNew={setIsNew}
        params={params}
        resource={selectedResource}
        roomId={roomId}
        setSelectedResource={setSelectedResource}
      />
      {resourcesList.length > 0 && (
        <>
          {isLedgerShowing && (
            <DataLedgerModal
              isOpen={isLedgerShowing}
              selectedPortfolio={selectedPortfolio}
              json={json}
              resourcesList={resourcesList}
              handleClose={() => setIsLedgerShowing(false)}
            />
          )}
          {isUserReportsShowing && (
            <UserReportsModal
              isOpen={isUserReportsShowing}
              report={report}
              reports_feature_type_id={id}
              json={json}
              resourcesList={resourcesList}
              handleClose={() => setIsUserReportsShowing(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export const CompanyTable = ({
  accessCode,
  isEmpty,
  json = {},
  passedParams,
  question_label,
  resourceListData,
  roomId,
}) => {
  const { feature_types = [], links = [], resource_label } = json;
  const routeParams = useParams();
  const params = !_isEmpty(passedParams) ? passedParams : routeParams;
  const { companyId } = params;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onDocumentsClick = e => {
    e.preventDefault();
    setSelectedResource({ ...companyData, isDocsShowingInitially: true });
    setIsModalOpen(true);
  };
  const onDocumentClick = (e, initialValue) => {
    if (initialValue) {
      e.preventDefault();
    }
  };

  const companyMeta = useMemo(() => {
    let documentsTotal = 0;
    let requiredDocumentsTotal = 0;
    feature_types.forEach(e => {
      if (e.feature_type.feature_type === 'document') {
        documentsTotal++;
        if (e.new_require) {
          requiredDocumentsTotal++;
        }
      }
    });
    return {
      documentsTotal,
      requiredDocumentsTotal,
    };
  }, [feature_types]);
  const { documentsTotal, requiredDocumentsTotal } = companyMeta;

  const companyData = useMemo(() => {
    if (isEmpty) {
      return [];
    }
    const companyResource = Object.entries(resourceListData || {})[0] || [];
    const companyFeatures = companyResource[1] || [];
    const companyKey = companyResource[0] || 'Your Company';
    return massageResource(companyFeatures, feature_types, companyKey);
  }, [feature_types, isEmpty, resourceListData]);
  const { resourceItems, documentsCompleted, documentItems, requiredDocumentsCompleted } =
    companyData;

  const allRequiredDocumentsCompleted =
    requiredDocumentsCompleted > 0 &&
    requiredDocumentsTotal === requiredDocumentsCompleted;
  // let documentsLabel = `${requiredDocumentsCompleted} of ${requiredDocumentsTotal} documents`;

  if (isEmpty) {
    return (
      <div className="reportingTable__wrapper reportingTable__wrapper--company">
        <div className="reportingTable__title">
          <h2>Company Summary</h2>
        </div>
        <div className="reportingTable">
          <h2 className="reportingTable__empty">{`No Company Data Found`}</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="reportingTable__wrapper reportingTable__wrapper--company">
      <div className="reportingTable__title">
        <h2>{question_label}</h2>
        <Button
          buttonType="icon"
          size="sm"
          onClick={e => {
            if (!e.defaultPrevented) {
              setSelectedResource(companyData);
              setIsModalOpen(true);
            }
          }}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </Button>
      </div>
      <div className="reportingTable">
        {!resourceItems && (
          <h2 className="reportingTable__empty">{`No ${resource_label} Data Found`}</h2>
        )}
        {resourceItems
          .filter(e => e.table_view && !e.isSublabel && !e.isDocument)
          .map((item, index) => (
            <div
              key={`resource-list-item-${index}-${item.feature_name}`}
              className="reportingTable__item is-company"
            >
              {index === 0 && (
                <>
                  <h3>{item.label}</h3>
                  {item.sublabel && <p>{item.sublabel}</p>}
                </>
              )}
              {index !== 0 && (
                <>
                  <h4>{item.feature_name}</h4>
                  <p>
                    {item.label}
                    {!item.value && !!item.action && !accessCode && (
                      <>
                        <Button
                          buttonType="link"
                          href={handlePath(item.action.link_text, companyId)}
                          size="sm"
                        >
                          {item.action.link_text}
                        </Button>
                      </>
                    )}
                  </p>
                </>
              )}
            </div>
          ))}
        {(documentsCompleted > 0 || requiredDocumentsTotal > 0) && (
          <div
            className={classNames(
              'reportingTable__item is-company reportingTable__documents',
              {
                'reportingTable__documents--completed': allRequiredDocumentsCompleted,
              },
            )}
            // onClick={onDocumentsClick}
          >
            <h4>
              Documents:
              {(!!documentsCompleted || requiredDocumentsTotal > 0) && (
                <>
                  {' ('}
                  <Button buttonType="link" size="xs" onClick={onDocumentsClick}>
                    view all
                  </Button>
                  )
                </>
              )}
            </h4>
            {documentItems.map(
              (doc, ind) =>
                !!doc.table_view &&
                (!!doc.initialValue || (!doc.initialValue && doc.new_require)) && (
                  <div
                    className="reportingTable__documentLabel"
                    onClick={e => onDocumentClick(e, doc.initialValue)}
                    key={`document-item-${ind}`}
                  >
                    {!doc.initialValue && (
                      <p>
                        {doc.new_require && <span>*</span>}
                        {doc.feature_name}
                      </p>
                    )}
                    {!!doc.initialValue && (
                      <FilePreviewer
                        accessCode={accessCode}
                        resourceId={doc.resourceId}
                        isLinkOnHover
                        boxFileId={doc.initialValue}
                        customLabel={doc.feature_name}
                        roomId={roomId}
                      />
                    )}
                  </div>
                ),
            )}
            {/* {requiredDocumentsTotal > 0 && (
              <Tooltip>
                <h4>required document{requiredDocumentsTotal > 1 && 's'}:</h4>
                {documentItems.map(
                  (document, ind) =>
                    document.new_require && (
                      <p
                        className={classNames('reportingTable__tooltipItem', {
                          'reportingTable__tooltipItem--empty': !document?.feature.value,
                        })}
                        key={`document-tooltip-${ind}`}
                      >
                        <FontAwesomeIcon
                          icon={['fal', document?.feature.value ? 'check' : 'times']}
                        />
                        {document.feature_name}
                      </p>
                    ),
                )}
              </Tooltip>
            )} */}
          </div>
        )}
      </div>
      {links.map(
        (link, ind) =>
          link.table_view && (
            <h4 className="reportingTable__subquestion" key={`resource-link-${ind}`}>
              <FontAwesomeIcon
                className="reportingTable__info"
                icon="exclamation-circle"
              />
              {link.label}
              <Button buttonType="link" href={handlePath(link.link_url, companyId)}>
                {link.link_text}
              </Button>
            </h4>
          ),
      )}
      <AddResourceModal
        documentsTotal={documentsTotal}
        requiredDocumentsTotal={requiredDocumentsTotal}
        handleClose={handleCloseModal}
        isLocked={!!accessCode}
        isCompany
        isOpen={isModalOpen}
        json={json}
        resource={selectedResource}
        setIsNew={() => {}}
        setSelectedResource={() => {}}
      />
    </div>
  );
};

export default ReportingTable;
