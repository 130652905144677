import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getCompanies,
  setCompany,
  updateCompanyPreferences,
  removeCompanyPreferences,
} from '../../redux/modules/Company/operations';
import {
  IsMultiCompany,
  // groupedCompanies,
  MyFormattedCompanies,
} from '../../redux/modules/Company/selectors';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { IsPartner } from '../../redux/modules/User/selectors';

import './EntitySelect.scss';
import '../MyDashboard/MyDashboard.scss';
import PageLoader from '../../components/PageLoader';
import { useHistory, useLocation, useParams } from 'react-router';
import handlePath from '../../utils/handlePath';
import { Button, DropdownAction, MyModal } from '../../components/common';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { someValuesEveryString } from '../../utils/FilterTools';
import { generateBem } from '../../utils/generateBem';
import { InputSelect, InputStyledTextField } from '../../components/inputs';
import { setNotice } from '../../redux/modules/UI/actions';
import classNames from 'classnames';
import { SearchInput } from '../../redux/modules/UI/selectors';
dayjs.extend(localizedFormat);

const sortNumbers = (a, b, sortIsDesc) => {
  if (sortIsDesc) {
    return b - a;
  } else {
    return a - b;
  }
};

const sortStrings = (a, b, sortIsDesc) =>
  sortIsDesc ? b.localeCompare(a) : a.localeCompare(b);

const sortByDate = (a, b, sortIsDesc) => {
  return sortIsDesc
    ? new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
    : new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
};

const EntitySelect = () => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const { companyId } = useParams();
  const location = useLocation();

  const { hash, search, state = {} } = location;
  const { isToggled, roomCode, isInitialLoad } = state || {};

  const companies = useSelector(MyFormattedCompanies);
  const searchInput = useSelector(SearchInput);

  const [isCompaniesFetched, setIsCompaniesFetched] = useState(false);
  const [isLoadingTimeout, setLoadingTimeout] = useState(false);
  const [isAddToGroupModalShowing, showAddToGroupModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [isAddManyToGroupModalShowing, showAddManyToGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  const passedState = { isToggled, roomCode };
  const isLoaded = isCompaniesFetched || isLoadingTimeout || companies.length > 0;

  const groupedCompanies = useMemo(() => {
    let updatedCompanies = companies.filter(task =>
      someValuesEveryString(task, [searchInput]),
    );
    let groupedEntities = [{ name: '', order: null, entities: [] }];

    updatedCompanies.forEach(company => {
      if (company.account_group && company.account_group !== 'ungrouped') {
        const groupIndex = groupedEntities.findIndex(
          group => group.name === company.account_group,
        );
        if (groupIndex !== -1) {
          let updatedGroup = groupedEntities[groupIndex];

          updatedGroup.entities.push(company);
          groupedEntities[groupIndex] = updatedGroup;
        } else {
          groupedEntities.push({
            name: company.account_group,
            order: company.account_order,
            value: company.account_group,
            label: company.account_group,
            entities: [company],
          });
        }
      } else {
        groupedEntities[0].entities.push(company);
      }
    });
    return groupedEntities.sort((a, b) => {
      return b.order - a.order;
    });
  }, [companies, searchInput]);

  useEffect(() => {
    const url = window.location.href;
    if (companies.length === 0 && !isCompaniesFetched && !url.includes('/new')) {
      dispatch(getCompanies()).then(p => {
        setIsCompaniesFetched(true);
        if (isInitialLoad && p.length === 0) {
          setNotice('No Workspaces found, redirecting to LP Portal.');
          replace({
            pathname: '/c/0/lp-portal/personal',
          });
        }
      });
    }
  }, [dispatch, companies.length, isCompaniesFetched, isInitialLoad, replace]);

  useEffect(() => {
    const timer = setTimeout(() => setLoadingTimeout(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleAddToGroupModalClose = (group, groupOrder, company) => {
    if (group) {
      let order = company.account_order;
      if (groupOrder) {
        order = groupOrder;
      } else {
        order = groupedCompanies[0].order + 1000000;
      }

      dispatch(updateCompanyPreferences({ group, order }, company.id)).then(() => {
        dispatch(setNotice('Added to Group.'));
        dispatch(getCompanies());
      });
    }
    showAddToGroupModal(false);
    setSelectedCompany({});
  };

  const handleShowGroupModal = company => {
    showAddToGroupModal(true);
    setSelectedCompany(company);
  };

  const handleAddManyToGroupModalClose = (group, selectedCompanies) => {
    if (group) {
      // let order = group.order;
      selectedCompanies.forEach((entity, entityIndex) =>
        dispatch(
          updateCompanyPreferences(
            { group: group.name, order: group.order },
            entity.id,
            entityIndex !== group.entities.length - 1,
          ),
        ).then(payload => {
          if (entityIndex === selectedCompanies.length - 1) {
            dispatch(setNotice('Companies added.'));
            dispatch(getCompanies());
          }
        }),
      );
    }
    showAddManyToGroupModal(false);
    setSelectedGroup({});
  };

  const handleShowAddManyToGroupModal = group => {
    showAddManyToGroupModal(true);
    setSelectedGroup(group);
  };

  return (
    <div className="dashboard__content">
      {/* <label className="myDashboard__search">
        <FontAwesomeIcon className="myDashboard__searchIcon" icon={['fal', 'search']} />
        <input
          autoComplete="off"
          placeholder="Search Companies"
          value={searchInput}
          name="companiesFilter"
          onChange={e => setSearchInput(e.target.value)}
        />
        {(searchInput || '').length > 0 && (
          <FontAwesomeIcon
            className="myDashboard__searchClear"
            icon={['fal', 'times']}
            onClick={() => setSearchInput('')}
          />
        )}
      </label> */}
      <section
        className={classNames('entitySelect__header', {
          'entitySelect__header--empty': isLoaded && companies.length === 0,
        })}
      >
        <h1>Pick your Organization Workspace</h1>
        <Button
          buttonType="link"
          onClick={() =>
            push({
              pathname: '/new-company',
              hash,
              search,
              state: { ...passedState, background: location },
            })
          }
        >
          <FontAwesomeIcon icon={['fal', 'plus']} /> Add Workspace
        </Button>
      </section>
      {!isLoaded && <PageLoader />}
      {isLoaded && companies.length === 0 && (
        <div className="entitySelect__groupList-none">
          <h2>
            No Connected Workspaces Found.
            <Button
              buttonType="primary"
              size="sm"
              onClick={e =>
                push(
                  handlePath(
                    { pathname: '/dashboard', hash, search, state: passedState },
                    companyId,
                  ),
                )
              }
            >
              View Dashboard
            </Button>
          </h2>
        </div>
      )}
      {isLoaded && companies.length > 0 && (
        <section className={'entitySelect__groups'}>
          {groupedCompanies.map((group, groupIndex) => (
            <EntityGroups
              key={`company-group-${group.name}`}
              group={group}
              groupIndex={groupIndex}
              groupedCompanies={groupedCompanies}
              handleShowGroupModal={handleShowGroupModal}
              handleShowAddManyToGroupModal={handleShowAddManyToGroupModal}
            />
          ))}
        </section>
      )}
      <AddToGroupModal
        company={selectedCompany}
        isShowing={isAddToGroupModalShowing}
        handleClose={handleAddToGroupModalClose}
        groupedCompanies={groupedCompanies}
      />
      <AddManyToGroupModal
        group={selectedGroup}
        isShowing={isAddManyToGroupModalShowing}
        handleClose={handleAddManyToGroupModalClose}
        companies={companies}
      />
    </div>
  );
};

const EntityGroups = ({
  group,
  groupIndex,
  handleShowGroupModal,
  groupedCompanies,
  handleShowAddManyToGroupModal,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { hash, search, state = {} } = location;
  const { isPlansShowing } = state || {};

  const currentAccountId = useSelector(getAccountId);
  const isMultiCompany = useSelector(IsMultiCompany);
  const isPartner = useSelector(IsPartner);

  const [groupRenameInput, setGroupRenameInput] = useState(group.name);
  const [isGroupRenameShowing, showGroupRename] = useState(false);

  const [sortIsDesc, setSortIsDesc] = useState(false);
  const [sortSlug, setSortSlug] = useState('date');
  const sortIcon = ['fal', sortIsDesc ? 'sort-amount-up' : 'sort-amount-down'];

  const sortedEntities = useMemo(() => {
    let updatedEntities = group.entities;
    if (sortSlug) {
      updatedEntities = updatedEntities.sort((a, b) => {
        if (sortSlug === 'name') {
          return sortStrings(a.name, b.name, sortIsDesc);
        } else if (sortSlug === 'role') {
          return sortStrings(a.role, b.role, sortIsDesc);
        } else if (sortSlug === 'partnerGroup') {
          if (a.partner_id && !b.partner_id) {
            return -1;
          } else if (!a.partner_id && b.partner_id) {
            return 1;
          } else if (a.partner_id && b.partner_id) {
            return sortStrings(a.partner, b.partner, sortIsDesc);
          } else {
            sortByDate(a, b);
          }
        } else if (sortSlug === 'missingSignatures') {
          return sortNumbers(a.documents.length, b.documents.length, sortIsDesc);
        }
        return sortByDate(a, b, sortIsDesc);
      });
    }
    return updatedEntities;
  }, [group.entities, sortSlug, sortIsDesc]);

  const handleSortClick = clickedFilter => {
    if (clickedFilter === sortSlug) {
      if (sortIsDesc) {
        setSortSlug('');
      }
      setSortIsDesc(!sortIsDesc);
    } else {
      setSortIsDesc(false);
      setSortSlug(clickedFilter);
    }
  };

  const handleGroupNameBlur = ev => {
    if (isGroupRenameShowing || ev.target.value !== group.name) {
      group.entities.forEach((entity, entityIndex) =>
        dispatch(
          updateCompanyPreferences(
            { group: ev.target.value },
            entity.id,
            entityIndex !== group.entities.length - 1,
          ),
        ).then(payload => {
          if (entityIndex === group.entities.length - 1) {
            dispatch(setNotice('Group Renamed.'));
            dispatch(getCompanies());
          }
        }),
      );
    }
  };

  const handleRemoveFromGroup = company => {
    // dispatch(removeCompanyPreferences(company.id)).then(() => {
    dispatch(removeCompanyPreferences(company.id)).then(() => {
      dispatch(getCompanies());
      dispatch(setNotice(`removed ${company.name} from ${company.group}`));
    });
  };

  const handleDeleteGroup = group => {
    group.entities.forEach((entity, entityIndex) =>
      dispatch(removeCompanyPreferences(entity.id)).then(payload => {
        if (entityIndex === group.entities.length - 1) {
          dispatch(setNotice('Group Removed.'));
          dispatch(getCompanies());
        }
      }),
    );
  };

  const handleAddToGroup = (company, selectedGroup) => {
    dispatch(
      updateCompanyPreferences(
        { group: selectedGroup.name, order: selectedGroup.order },
        company.id,
      ),
    ).then(() => {
      dispatch(setNotice('Added to Group.'));
      dispatch(getCompanies());
    });
  };

  const handleSortGroup = (group, groupIndex) => {
    let aboveGroupOrder = groupedCompanies[groupIndex - 1].order;
    let order = group.order;
    if (groupIndex > 1) {
      let secondAboveGroupOrder = groupedCompanies[groupIndex - 2].order;
      order = (aboveGroupOrder + secondAboveGroupOrder) / 2;
    } else {
      order = aboveGroupOrder + 1000000;
    }
    group.entities.forEach((entity, entityIndex) =>
      dispatch(updateCompanyPreferences({ order }, entity.id)).then(payload => {
        if (entityIndex === group.entities.length - 1) {
          dispatch(setNotice('Group Sorted.'));
          dispatch(getCompanies());
        }
      }),
    );
  };
  return (
    <div className="entitySelect__group">
      {!!group.name && (
        <div className="entitySelect__groupHeader">
          {!isGroupRenameShowing && <h2>{group.name}</h2>}
          {isGroupRenameShowing && (
            <InputStyledTextField
              name="group_name"
              value={groupRenameInput}
              onChange={ev => setGroupRenameInput(ev.target.value)}
              onBlur={handleGroupNameBlur}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  setGroupRenameInput(group.name);
                  showGroupRename(false);
                }
              }}
            />
          )}
          {!group.ungrouped && (
            <DropdownAction className="entitySelect__dropdown" left>
              <div className={'entitySelect__dropdownHeader'}>
                <h4>Group Options</h4>
                <div className={'entitySelect__dropdownDivider'} />
              </div>
              <Button
                buttonType="secondary"
                size="sm"
                isDisabled={groupIndex === 0}
                onClick={ev => handleSortGroup(group, groupIndex)}
              >
                Sort Up
              </Button>
              <Button
                buttonType="secondary"
                size="sm"
                isDisabled={groupedCompanies.length - 2 === groupIndex} // Doesn't count non-group companies object.
                onClick={() =>
                  handleSortGroup(groupedCompanies[groupIndex + 1], groupIndex + 1)
                } // Always sort up.
              >
                Sort Down
              </Button>
              <Button
                buttonType="secondary"
                size="sm"
                onClick={ev => showGroupRename(true)}
              >
                Rename Group
              </Button>
              <Button
                buttonType="secondary"
                size="sm"
                onClick={ev => handleShowAddManyToGroupModal(group)}
              >
                Add Multiple Companies to Group
              </Button>
              <Button
                buttonType="secondary"
                size="sm"
                isWarning
                onClick={() => handleDeleteGroup(group)}
              >
                Delete Group
              </Button>
            </DropdownAction>
          )}
        </div>
      )}
      <div
        className={classNames('entitySelect__groupList', {
          fourCols: !isPartner && isMultiCompany,
          fiveCols: isPartner,
          'entitySelect__groupList--ungrouped': groupIndex !== 0 && !group.name,
        })}
      >
        <div className="entitySelect__groupListHeaders">
          <h4
            className={classNames('entitySelect__groupListHeader', {
              'entitySelect__groupListHeader--active': sortSlug === 'name',
            })}
            onClick={() => handleSortClick('name')}
          >
            Name
            {sortSlug === 'name' && (
              <FontAwesomeIcon
                className="transaction-progress__sorting-icon"
                icon={sortIcon}
              />
            )}
            {sortSlug !== 'name' && <div className="transaction-progress__placeholder" />}
          </h4>
          <h4
            className={classNames('entitySelect__groupListHeader', {
              'entitySelect__groupListHeader--active': sortSlug === 'date',
            })}
            onClick={() => handleSortClick('date')}
          >
            Date Created
            {sortSlug === 'date' && (
              <FontAwesomeIcon
                className="transaction-progress__sorting-icon"
                icon={sortIcon}
              />
            )}
          </h4>
          <h4
            className={classNames('entitySelect__groupListHeader', {
              'entitySelect__groupListHeader--active': sortSlug === 'role',
              'entitySelect__groupListHeader--last': !isPartner && !isMultiCompany,
            })}
            onClick={() => handleSortClick('role')}
          >
            Role
            {sortSlug === 'role' && (
              <FontAwesomeIcon
                className="transaction-progress__sorting-icon"
                icon={sortIcon}
              />
            )}
          </h4>
          {isPartner && (
            <h4
              className={classNames('entitySelect__groupListHeader', {
                'entitySelect__groupListHeader--active': sortSlug === 'partnerGroup',
              })}
              onClick={() => handleSortClick('partnerGroup')}
            >
              Partner Group
              {sortSlug === 'partnerGroup' && (
                <FontAwesomeIcon
                  className="transaction-progress__sorting-icon"
                  icon={sortIcon}
                />
              )}
            </h4>
          )}
          {(isPartner || isMultiCompany) && (
            <h4
              className={classNames(
                'entitySelect__groupListHeader entitySelect__groupListHeader--last',
                {
                  'entitySelect__groupListHeader--active':
                    sortSlug === 'missingSignatures',
                },
              )}
              onClick={() => handleSortClick('missingSignatures')}
            >
              Missing Signatures
              {sortSlug === 'missingSignatures' && (
                <FontAwesomeIcon
                  className="transaction-progress__sorting-icon"
                  icon={sortIcon}
                />
              )}
            </h4>
          )}
        </div>
        {sortedEntities.map((company, companyIndex) => (
          <div
            className={classNames('entitySelect__groupListRow', {
              'entitySelect__groupListRow--first': companyIndex === 0 && groupIndex !== 0,
            })}
            onClick={ev => {
              if (!ev.defaultPrevented) {
                dispatch(
                  setCompany(
                    company.id,
                    {
                      pathname: '/home',
                      hash,
                      search,
                      state: { isPlansShowing },
                    },
                    company.id === currentAccountId,
                  ),
                );
              }
            }}
            key={`company-option-${company.id}`}
          >
            <div className="entitySelect__groupListItem entitySelect__groupListItem--first">
              <h4
                className="entitySelect__groupListItem-company-name"
                style={{ fontSize: 16 }}
              >
                {company.name}
                {company.id === currentAccountId && ' (current)'}
              </h4>
            </div>
            <div className="entitySelect__groupListItem">
              <h4 className="entitySelect__groupListItem-label">
                {dayjs(company.created_at).format('L')}
              </h4>
            </div>
            <div
              className={classNames('entitySelect__groupListItem', {
                'entitySelect__groupListItem--last': !isPartner && !isMultiCompany,
              })}
            >
              <h4 className="entitySelect__groupListItem-label">
                {company.role || '--'}
              </h4>
            </div>
            {isPartner && (
              <div className="entitySelect__groupListItem">
                <h4 className="entitySelect__groupListItem-label">{company.partner}</h4>
              </div>
            )}
            {(isPartner || isMultiCompany) && (
              <div className="entitySelect__groupListItem entitySelect__groupListItem--last">
                <h4 className="entitySelect__groupListItem-label">
                  {company.documents.length || '--'}
                </h4>
                <DropdownAction className="entitySelect__dropdown" right>
                  <div className={'entitySelect__dropdownHeader'}>
                    <h4>Company Options</h4>
                    <div className={'entitySelect__dropdownDivider'} />
                  </div>
                  {groupedCompanies.map(
                    p =>
                      p.name &&
                      p.name !== company.account_group && (
                        <Button
                          key={`add-to-${p.name}-action`}
                          buttonType="secondary"
                          size="sm"
                          onClick={ev => handleAddToGroup(company, p)}
                        >
                          {`Add to "${p.name}" Group`}
                        </Button>
                      ),
                  )}
                  <Button
                    buttonType="secondary"
                    size="sm"
                    onClick={ev => handleShowGroupModal(company)}
                  >
                    Add to new Group
                  </Button>
                  {!!group.name && (
                    <Button
                      buttonType="secondary"
                      size="sm"
                      isWarning
                      onClick={() => handleRemoveFromGroup(company)}
                    >
                      {`Remove From "${group.name}" Group`}
                    </Button>
                  )}
                </DropdownAction>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const bem = generateBem('entitySelect');

const AddToGroupModal = ({ company, isShowing, handleClose, groupedCompanies }) => {
  const [group, setGroup] = useState('');

  useEffect(() => {
    if (company.account_group && isShowing) {
      setGroup(company.account_group);
    }
  }, [company.account_group, isShowing]);

  let updatedGroup = typeof group === 'string' ? { value: group, label: group } : group;

  return (
    <MyModal
      className={bem('modal')}
      isOpen={isShowing}
      onRequestClose={() => {
        setGroup('');
        handleClose(false);
      }}
      overlayClassName={bem('modalOverlay')}
    >
      <div className={bem('modalHeader')}>
        <h2>
          {`${
            company.account_group && company.account_group !== 'ungrouped'
              ? 'Change'
              : 'Add'
          } to new or existing group`}
        </h2>
        <FontAwesomeIcon
          className={bem('modalExit')}
          onClick={() => handleClose(false)}
          icon="times"
        />
      </div>
      <h3 className={bem('modalSubheader')}>{company.name}</h3>
      <InputSelect
        isCreatable
        name="groups"
        label="Groups"
        value={updatedGroup}
        onChange={val => {
          setGroup(val);
        }}
        options={groupedCompanies}
      />
      <div className={bem('modalActions')}>
        <Button onClick={() => handleClose(false)} buttonType="secondary">
          Cancel
        </Button>
        <Button onClick={ev => handleClose(group?.value || group, group?.order, company)}>
          {`${
            company.account_group && company.account_group !== 'ungrouped'
              ? 'Change'
              : 'Add to'
          } Group`}
        </Button>
      </div>
    </MyModal>
  );
};

const AddManyToGroupModal = ({ group, isShowing, handleClose, companies }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const filteredCompanies = companies.filter(
    company =>
      (group?.entities || []).findIndex(
        entity => entity.account_id === company.account_id,
      ) === -1,
  );

  return (
    <MyModal
      className={bem('modal')}
      isOpen={isShowing}
      onRequestClose={() => {
        setSelectedCompanies([]);
        handleClose(false);
      }}
      overlayClassName={bem('modalOverlay')}
    >
      <div className={bem('modalHeader')}>
        <h2>Add Multiple Companies to Group</h2>
        <FontAwesomeIcon
          className={bem('modalExit')}
          onClick={() => handleClose(false)}
          icon="times"
        />
      </div>
      <h3 className={bem('modalSubheader')}>{group.name}</h3>
      <InputSelect
        isClearable
        isMulti
        name="groups"
        label="Groups"
        value={selectedCompanies}
        onChange={val => {
          setSelectedCompanies(val);
        }}
        options={filteredCompanies}
      />
      <div className={bem('modalActions')}>
        <Button onClick={() => handleClose(false)} buttonType="secondary">
          Cancel
        </Button>
        <Button onClick={ev => handleClose(group, selectedCompanies)}>
          Add Companies to Group
        </Button>
      </div>
    </MyModal>
  );
};

export default EntitySelect;
