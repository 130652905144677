import { get } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';
import PandaDocStatusDict from '../../../utils/PandaDocStatusDict';

export const isFetchingWithHooks = defaultMemoize(state =>
  get(state, 'Document.isFetching', false),
);

export const DocumentError = defaultMemoize(state =>
  get(state, 'Document.documentError', ''),
);

export const UserDocuments = defaultMemoize(state => {
  const documents = get(state, `Document.userDocuments.byArr`, []);
  let filteredDocuments = [];

  documents.forEach(doc => {
    const viewableStates = [
      'document.sent',
      'document.viewed',
      'document.completed',
      'document.waiting_approval',
      'document.waiting_pay',
      'document.upload',
      'document.draft',
    ];
    const isDocNotDeletedOrDeclined =
      doc.status !== 'document.deleted' && doc.status !== 'document.declined';
    const isDocStateViewableOrUploaded =
      viewableStates.includes(doc.status) || doc.box_file_id;
    if (isDocNotDeletedOrDeclined && isDocStateViewableOrUploaded) {
      filteredDocuments.push(doc);
    }
  });
  return filteredDocuments;
});

export const UserActionableDocuments = createSelector(UserDocuments, documents =>
  documents.filter(doc => !doc.box_file_id),
);

export const getDocumentsArrWithHooks = defaultMemoize(state =>
  get(state, `Document.documents.byArr`, []),
);

export const getAllDocumentsArrWithHooks = defaultMemoize(state =>
  get(state, `Document.allDocuments.byArr`, []),
);

export const getAllDocumentsForSelect = defaultMemoize(state => {
  const documents = get(state, `Document.allDocuments.byArr`, []);
  return documents
    .filter(
      e =>
        e.status !== 'document.deleted' &&
        ((e.box_file_id && !e.status) ||
          !['N/A', 'Voided', 'Deleted'].includes(PandaDocStatusDict(e.status))),
    )
    .map(e => ({
      ...e,
      label: e.filename,
      value: e.id,
      stateText:
        (e.revision_count > 0 && 'Awaiting Approval') ||
        (e.box_file_id && !e.status && 'Uploaded') ||
        PandaDocStatusDict(e.status),
    }));
});

export const DocumentsByProject = defaultMemoize((state, projectId) =>
  get(state, `Document.documents.byProject[${projectId}]`, []),
);

export const useAllDocuments = createSelector(getAllDocumentsArrWithHooks, documents =>
  documents.reduce((dict, e, ind) => {
    let viewableStates = [
      'document.sent',
      'document.viewed',
      'document.completed',
      'document.waiting_approval',
      'document.waiting_pay',
      'document.upload',
      'document.draft',
    ];

    const isDocNotDeleted = e.status !== 'document.deleted';
    const isDocStateViewableOrUploaded =
      viewableStates.includes(e.status) || e.box_file_id;
    if (isDocNotDeleted && isDocStateViewableOrUploaded) {
      dict.push(e);
    }
    return dict;
  }, []),
);

export const useDocumentsNeedSigning = createSelector(useAllDocuments, documents =>
  documents.reduce((dict, e, ind) => {
    let included_states = [
      'document.completed',
      'document.waiting_approval',
      'document.waiting_pay',
      'document.upload',
      'document.draft',
    ];

    if (
      e.recipients.length > 0 &&
      !e.box_file_id &&
      !included_states.includes(e.status)
    ) {
      dict.push(e);
    }
    return dict;
  }, []),
);

export const useDocumentsNeedRevision = createSelector(useAllDocuments, documents =>
  documents.reduce((dict, e, ind) => {
    if (e.revision_count > 0 && !e.box_file_id) {
      dict.push(e);
    }
    return dict;
  }, []),
);

export const useDocumentsCompleted = createSelector(useAllDocuments, documents =>
  documents.reduce((dict, e, ind) => {
    if (e.box_file_id) {
      dict.push(e);
    }
    return dict;
  }, []),
);

export const getActionableDocuments = createSelector(
  getAllDocumentsArrWithHooks,
  // getDocumentsArrWithHooks,
  documents =>
    documents.reduce((dict, e, ind) => {
      let included_states = [
        'document.sent',
        'document.viewed',
        'document.waiting_approval',
        'document.waiting_pay',
      ];

      if (!included_states.includes(e.status) || !e.account_task_id) {
        return dict;
      }
      dict.push(e);
      return dict;
    }, []),
);
