import React, { useMemo } from 'react';

import classNames from 'classnames';

import { Button } from '../common';
import './ReportingTable.scss';
import handlePath from '../../utils/handlePath';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer';
import PropTypes from 'prop-types';

// const SUBHEADERS_WITH_TYPE = ['authorized_shares', 'equity_shares', 'quarterly_revenue'];
// const SUBHEADERS = ['email', 'investment_type', 'ip_type', 'state_type', 'equity_class'];

export const ReportingRow = ({
  accessCode,
  companyId,
  isAdmin,
  isUser,
  // isTableFormat,
  requiredDocumentsTotal,
  reports_feature_type_id,
  resource,
  resourceIndex,
  roomId,
  setIsModalOpen,
  setSelectedResource,
}) => {
  const { documentsCompleted, documentItems, resourceItems } = resource;

  const filteredResourceItems = useMemo(() => {
    let filteredArr = [];
    // let isSubheaderShowing = false;
    resourceItems.forEach(e => {
      //   const isSubheaderWithType = SUBHEADERS_WITH_TYPE.includes(e.feature_type.name);
      //   const isSubheader = SUBHEADERS.includes(e.feature_type.name);
      //   if (e.isDocument) {
      //     return;
      //   } else if (!isSubheaderShowing && isSubheaderWithType) {
      //     filteredArr[0] = {
      //       ...filteredArr[0],
      //       sublabel: e.feature.value ? `${e.label} ${e.feature_name}` : '',
      //     };
      //     isSubheaderShowing = true;
      //   } else if (!isSubheaderShowing && isSubheader) {
      //     filteredArr[0] = {
      //       ...filteredArr[0],
      //       sublabel: e.feature.value ? e.label : '',
      //     };
      //     isSubheaderShowing = true;
      //   } else if (e.table_view) {
      //     filteredArr.push(e);
      //   }
      // });
      if (e.isDocument) {
        return;
      } else if (e.table_view) {
        filteredArr.push(e);
      }
    });
    return filteredArr;
  }, [resourceItems]);

  const onResourceClick = (e, resource) => {
    if (resource.isDocument) {
      e.preventDefault();
      setSelectedResource({ ...resource, isDocsShowingInitially: true });
      setIsModalOpen(true);
    }
  };
  // const onDocumentsClick = e => {
  //   e.preventDefault();
  //   setSelectedResource({ ...resource, isDocsShowingInitially: true });
  //   setIsModalOpen(true);
  // };
  const onDocumentClick = (e, initialValue) => {
    if (initialValue) {
      e.preventDefault();
    }
  };

  const isDocumentsShowing = documentsCompleted > 0 || requiredDocumentsTotal > 0;

  return (
    <div
      className={classNames('reportingTable__row', {
        firstRow: resourceIndex < 1,
        isDocumentsShowing,
      })}
      onClick={e => {
        if (!e.defaultPrevented) {
          setSelectedResource(resource);
          setIsModalOpen(true);
        }
      }}
    >
      {filteredResourceItems.map((resourceFeature, index) => {
        const companyName = resourceFeature.feature?.account?.name;
        return (
          <div
            key={`resource-list-item-${index}-${resourceFeature.feature_name}`}
            className={classNames('reportingTable__item', {
              first: index === 0,
              // 'reportingTable__item--sublabel': resourceFeature.sublabel,
            })}
            onClick={e => onResourceClick(e, resourceFeature)}
          >
            {/* {index === 0 && (
            <>
              {isUser && <h2>{(resourceItems || [])[0]?.feature?.account?.name}</h2>}
              <h3>{resourceFeature.label}</h3>
              {resourceFeature.sublabel && <p>{resourceFeature.sublabel}</p>}
            </>
          )} */}
            {index === 0 && (
              <>
                {/* {!isTableFormat && <h4>{resourceFeature.feature_name}</h4>} */}
                <h3>{resourceFeature.label}</h3>
                {!!companyName && <h4>{companyName}</h4>}
              </>
            )}
            {index !== 0 && (
              <>
                {/* {!isTableFormat && <h4>{resourceFeature.feature_name}</h4>} */}
                <p>
                  {resourceFeature.label}
                  {!resourceFeature.feature.value &&
                    !!resourceFeature.action &&
                    !accessCode && (
                      <>
                        <Button
                          buttonType="link"
                          href={handlePath(resourceFeature.action.link_url, companyId)}
                          size="sm"
                        >
                          {resourceFeature.action.link_text}
                        </Button>
                      </>
                    )}
                </p>
              </>
            )}
          </div>
        );
      })}
      {(documentsCompleted > 0 || requiredDocumentsTotal > 0) && (
        <div
          className={classNames('reportingTable__item reportingTable__documents', {
            firstRow: resourceIndex < 1,
          })}
        >
          {/* <h4>
            Documents
            {(!!documentsCompleted || requiredDocumentsTotal > 0) && (
              <>
                {' ('}
                {documentItems.length > 1 && (
                  <Button buttonType="link" size="xs" onClick={onDocumentsClick}>
                    view all
                  </Button>
                )}
                {documentItems.length === 1 && '1'})
              </>
            )}
          </h4> */}
          {documentItems.map(
            (doc, ind) =>
              !!doc.table_view &&
              (!!doc.initialValue || (!doc.initialValue && doc.new_require)) && (
                <div
                  className="reportingTable__documentLabel"
                  onClick={e => onDocumentClick(e, doc.initialValue)}
                  key={`document-item-${ind}`}
                >
                  {/* {!doc.initialValue && doc.new_require && (
                    <FontAwesomeIcon
                      className="reportingTable__documentRequired"
                      icon={['fal', 'times']}
                    />
                  )} */}
                  {!doc.initialValue && (
                    <p>
                      {doc.new_require && <span>*</span>}
                      {doc.feature_name}
                    </p>
                  )}
                  {!!doc.initialValue && (
                    <FilePreviewer
                      accessCode={accessCode}
                      exchangeToken={doc.feature?.exchange_token}
                      isDataRoom={!!accessCode && !isAdmin}
                      isUserTable={isUser}
                      // isThumbnailShowing
                      // height={140}
                      // width={140}
                      reportsFeatureTypeId={reports_feature_type_id}
                      resourceId={doc.resourceId}
                      isLinkOnHover
                      boxFileId={doc.initialValue}
                      customLabel={doc.feature_name}
                      roomId={roomId}
                    />
                  )}
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

ReportingRow.propTypes = {
  accessCode: PropTypes.string,
  companyId: PropTypes.string,
  isAdmin: PropTypes.bool,
  requiredDocumentsTotal: PropTypes.number,
  reports_feature_type_id: PropTypes.number,
  resource: PropTypes.object,
  resourceIndex: PropTypes.number,
  setIsModalOpen: PropTypes.func,
  setSelectedResource: PropTypes.func,
};
